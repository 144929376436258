span:not(.title-col > *) { white-space: nowrap; }

/* Vertical scroll for bets column */
.scroll-col {
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
    flex-wrap: unset !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-col::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.scroll-col {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.pl-card {
    cursor: pointer;
}
.pl-card:hover, .pl-card.selected { background-color: #212121 !important; border-color: #919191 !important; }
.btn-stuck {
    position: fixed;
    right: 25px;
    bottom: 50px;
}
i { cursor: pointer; }


/* Horizontal scrolls for profit/loss cards */
.overflow-x-scroll {
    overflow-x: scroll !important;
    flex-wrap: unset !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.overflow-x-scroll::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.overflow-x-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


.btn-rounded { border-radius: 50%; }
.bet-list { list-style: circle; }
.real-content {cursor: pointer; }