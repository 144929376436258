.cursor-pointer { cursor: pointer !important; }
.ff-g { font-family: sans-serif; }
.table, .table > :not(caption) > * > * { color: white; background-color: unset; }
.table { color: white; background-color: #212529; }
.tight-shadow { box-shadow: rgba(0, 0, 0, 0.24) 0 1px 8px; }
/******************************************************************************************************************************************************************************************************************
* PLAYER INFO
******************************************************************************************************************************************************************************************************************/
@media (min-width: 546px) {
    .position-font { font-size: 16px; }
    .opponent-font { font-size: 18px; }
}
@media (max-width: 545px) {
    h2 { font-size: 20px; }
    .position-font { font-size: 13px; }
    .opponent-font { font-size: 14px; }
}



/******************************************************************************************************************************************************************************************************************
* STAT & LINE BUBBLES
******************************************************************************************************************************************************************************************************************/
hr { border: 1px solid #6F38C5 !important; margin-top: -5px !important; margin-bottom: 7px !important; opacity: .95 !important; }
.border-dark3 { border-color: #313131 !important; }
.border-bubble { border-color: #2c3237 !important; }
.upper-border-purple { border-top: 2px solid #6F38C5 !important; border-left: 2px solid #6F38C5 !important; border-right: 2px solid #6F38C5 !important; }
.lower-border-purple { border-bottom: 2px solid #6F38C5 !important; border-left: 2px solid #6F38C5 !important; border-right: 2px solid #6F38C5 !important; }
.border-purple { border-color: #6F38C5 !important; }
.rounded-top { border-top-left-radius: 10px; border-top-right-radius: 10px; }
.rounded-bottom { border-bottom-left-radius: 10px; border-bottom-right-radius: 10px; }
.rounded-bottom-left { border-bottom-left-radius: 5px; }
.rounded-bottom-right { border-bottom-right-radius: 5px; }
@media (min-width: 546px) {
    .fs-header { font-size: 14px; }
    .fs-odds { font-size: 14px; }

    .fs-cust { font-size: 1.25em; }
    .fs-cust-sm { font-size: 0.7em; display: inline-block; }
}
@media (max-width: 545px) {
    .fs-header { font-size: 13px; }
    .fs-odds { font-size: 13px; }

    .fs-cust { font-size: 1em; }
    .fs-cust-sm { font-size: 0.75em; }

    .fs-18 { line-height: 22px; }
}
.line-bubble { cursor: pointer; }



/******************************************************************************************************************************************************************************************************************
* GENERAL CHART
******************************************************************************************************************************************************************************************************************/
.w-20 { width: 20% !important; }
.vs-selector {
    cursor: pointer;
}
.vs-selector:hover {
    border-bottom: 1px solid white;
}
.chartRow {
    max-height: 200px;
}
.chartCol {
    position: relative;
    width: 80vw;
    border-radius: 10px;
}
.chartCol > canvas {
    max-height: 200px !important;
}
.hit-count-row {
    width: 98%;
    overflow-x: scroll;
    flex-wrap: unset !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hit-count-row::-webkit-scrollbar { display: none; }
/* Hide scrollbar for IE, Edge and Firefox */
.hit-count-row {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
@media (min-width: 546px) {
    .hit-rate-col {
        cursor: pointer;
        width: 85px;
    }
    .hit-rate-col-wide {
        cursor: pointer;
        width: 100px;
    }
}
@media (max-width: 545px) {
    .hit-rate-col {
        cursor: pointer;
        width: 75px;
        font-size: 12px;
    }
    .hit-rate-col-wide {
        cursor: pointer;
        width: 90px;
        font-size: 12px;
    }
}
@media (max-width: 545px) {
    .centerer { width: 0% !important; padding: 0 !important; }
}



/******************************************************************************************************************************************************************************************************************
* GAME LOG TABLE
******************************************************************************************************************************************************************************************************************/
@media (min-width: 546px) {
    .game-log-table {
        width: 100%;
        /* max-width: 100%; */
        overflow-x: scroll;
        font-size: 14px;
    }
}
@media (max-width: 545px) {
    .game-log-table {
        width: 100%;
        /* max-width: 100%; */
        overflow-x: scroll;
        font-size: 12px;
    }
}
.game-log-table > table > tbody > tr:hover { background-color: #383f45 !important; }
.game-selected { background-color: #2c3237 !important; }
.whitespace-nowrap { white-space: nowrap; }

.header-btn { border: none !important; }






.mx-001 { margin-right: -1px; }