/******************************************************************************************************************************************************************************************************************
* GENERAL OVERALL
******************************************************************************************************************************************************************************************************************/
.logo-br { border-radius: 15%; }
.orbitron { font-family: 'Orbitron', sans-serif; font-weight: 900; }
.ff-orbitron { font-family: 'Orbitron'; }
.ff-reg { font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; font-weight: 400; }
.bg-header { background: #6F38C5; }
.bg-home { background-image: linear-gradient(#6F38C5, black, #4d2789, black, #6F38C5); }
.bg-bubble { background-color: #2c3237 !important; }
.bg-darkest { background-color: #212529 !important; }
.bg-dark { background-color: #272727 !important; }
.bg-dark2 { background-color: #242424 !important; }
.bg-dark3 { background-color: #313131 !important; }
.bg-dark4 { background-color: #404040 !important; }
.bg-orange { background-color: #fd7e14 !important; }
.bg-blue { background-color: #1f375b !important; }
.custom-shadow { box-shadow: 0 0 1rem rgba(0,0,0,.15) !important; }
.nav-link:hover:not(.active) { text-decoration: underline; text-decoration-thickness: 3px; text-decoration-color: #592d9d; text-underline-offset: 5px; }
.active:not(.carousel-item) { text-decoration: underline !important; text-decoration-thickness: 3px !important; text-decoration-color: #4d2789 !important; text-underline-offset: 5px !important; }
.card { border: 1px solid #383f45 !important; }
.form-control-label-light { color: white; }
.form-control:focus {
    border-color: #6F38C5;
    box-shadow: none;
    outline: 0 none;
}
.form-control.search { height: 30px; border-radius: 5px !important; background: #212529 !important; border: 1px solid #515151 !important; color: white !important; }
.mt-07 { margin-top: .7rem !important; }
.mb-01 { margin-bottom: -1px; }
hr.login-hr { border: 1px solid #6F38C5 !important; margin-top: -5px !important; margin-bottom: 20px !important; opacity: .95 !important; }
.text-success2 { color: rgba(49, 200, 49, 1) !important; }
.text-danger2 { color: rgba(255, 51, 51, 1) !important }
.ps-01 { padding-left: .35rem !important; }
.px-12 { padding: 0 12px !important; }
.py-01 { padding: .15rem 0; }
.underline { text-decoration: underline !important; cursor: pointer !important; }

a:not(.navbar-brand):not(.nav-link) { all: unset; }
.navbar { font-family: 'Orbitron', sans-serif; font-weight: 900; }
@media (max-width: 545px) {
    .nav-item { margin-right: 0 !important; }
}
.ls-sm {
    font-size: 30px !important;
    letter-spacing: -0.025em !important;
}
.fs-18 { font-size: 18px; }
.fs-16 { font-size: 16px; }
.fs-14 { font-size: 14px; }
.fs-13 { font-size: 13px; }
.fs-12 { font-size: 12px; }
.px-25 { padding: 0 .75rem !important; }
.text-light-orange { color: #fd9843 !important; }
.text-orange { color: #fd7e14 !important; }
.text-purple { color: #6f75f9 !important; }
.text-dark-purple { color: #6F38C5 !important; }
.text-header { color: #6F38C5 !important; }
.bg-purple { background-color: #6F38C5; }
.bb-purple { border-bottom: 1px solid #6F38C5; }
i:not(.fa-check-circle):not(.fa-ban):not(.fa-sync-alt) { vertical-align: top !important; }
.w-350 { min-width: 300px; width: 350px; }
.form-check-input, .form-check-label { cursor: pointer !important; }
.bottom-10 { bottom: 10% !important; }
.no-border-bottom { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
.no-border-top { border-top-left-radius: 0; border-top-right-radius: 0; }



/******************************************************************************************************************************************************************************************************************
* LOGIN / SIGNUP
******************************************************************************************************************************************************************************************************************/
/* .form-control#name, .form-control#email, .form-control#password, .form-control#password2 { height: 40px; } */
.border-error { border: 1px solid #dc3545 !important; }
.text-error { color: #dc3545 !important; } 
a.sign-up { cursor: pointer !important; }
.spinner-grow-2x {
    width: 10rem;
    height: 10rem;
}



/******************************************************************************************************************************************************************************************************************
* SLIDING IMAGE ON TOP OF SITE WHEN LOADING
******************************************************************************************************************************************************************************************************************/
.sliding-background-still {
    display: block;
    width: 100%;
    height: .2rem;
    background: url('../public/colors.png');
    background-size: cover;
}
.sliding-background-move {
    display: block;
    width: 100%;
    height: .2rem;
    background: url('../public/colors.png');
    animation: bgScroll 13s linear infinite;
}
@keyframes bgScroll {
    0% { background-position : 0px 0px; }
    100% { background-position : -500% 0px; }
}



/******************************************************************************************************************************************************************************************************************
* HOME PAGE
******************************************************************************************************************************************************************************************************************/
@media (min-width: 576px) {
    .w-sm-30 { width: 30% !important; }
}
.rounded-top { border-top-left-radius: 10px; border-top-right-radius: 10px; }
.card-design {
    background: linear-gradient(
        300deg,
        #9369d4 0%,
        #723cc7 33%,
        #9369d4 100%
    );
    /* border: 1px solid white !important; */
}
#sports > div > div > div > .card { cursor: default !important; }
#subscriptions > div > div > div > .card { cursor: default !important; }
.card-design-reverse {
    background: linear-gradient(
        -300deg,
        #9369d4 0%,
        #723cc7 33%,
        #9369d4 100%
    );
    /* border: 1px solid white !important; */
}
.border-orange { border: 1px solid #fd7e14 !important; }
ul.sports { list-style-type: none; }
ul.sports li:before {
    content: '-';
    font-weight: bold;
    position: absolute;
    margin-left: -15px;
}
ul.sports li { padding: 7px 0; }
.accordion-button.collapsed:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0) !important;
}
.accordion-button:not(.collapsed):focus { box-shadow: 0 0 0 0.25rem #9369d4 !important; }
.accordion-button:after { background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important; }

/******************************************************************************************************************************************************************************************************************
* PRODUCT IMAGES
******************************************************************************************************************************************************************************************************************/
.image1-div{
    background-image: url('../public/iphones.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 0;
}
@media (min-width: 992px) {
    .image1-div{
        width: 70%;
        padding-top: 40%;
    }
}
@media (max-width: 991px) {
    .image1-div{
        width: 100%;
        padding-top: 100%;
        margin-left: 5% !important;
    }
}

.image2-div{
    background-image: url('../public/devices.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 0;
}
@media (min-width: 992px) {
    .image2-div{
        width: 100%;
        padding-top: 60%;
    }
}
@media (max-width: 991px) {
    .image2-div{
        width: 100%;
        padding-top: 65%;
    }
}