/******************************************************************************************************************************************************************************************************************
* SEARCH BAR ROW RESIZING
******************************************************************************************************************************************************************************************************************/
@media (max-width: 749px) {
    .header-col { margin-top: 4px !important; }
    h3 { font-size: 22px !important; }
}
#search { font-size: 13px; }
@media (min-width: 650px) { #search { width: 50%; } }
@media (max-width: 649px) { #search { width: 100%; font-size: 13px; } }
@media (min-width: 546px) { .mt-004 { margin-top: -.5rem !important; } }
@media (min-width: 546px) and (max-width: 800px) { .mt-004 { margin-top: -.4rem !important; } }
@media (max-width: 545px) { .mt-004 { margin-top: -.3rem !important; } }
.orbitron { font-family: 'Orbitron', sans-serif; }
@media (max-width: 649px) { .page-title { padding-top: 0.2em; font-size: 20px !important; } }


/******************************************************************************************************************************************************************************************************************
* FILTERS
******************************************************************************************************************************************************************************************************************/
.filters-btn {
    border: none !important;
    font-size: 0.9rem;
}
/* .filter_div {
    border-radius: 10px;
    border: 2px solid #353839;
}
.filter:hover {
    cursor: pointer;
    background: #414546;
    border-radius: 10px;
}
.selected {
    cursor: pointer;
    background: #353839;
    border-radius: 10px;
}
.br-50 { border-radius: 20px; } */
.b-left { border-left: 1px solid red; padding-left: 10px; padding-right: 0; }
.bubble {
    border: 2px solid darkgrey;
    border-radius: 5px;
    color: white;
    margin-right: 10px;
    cursor: pointer;
    font-weight: bold;
    font-family: 'Orbitron', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: .03rem;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    /* letter-spacing: .01rem; */
    /* padding-bottom: 1px; */

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.bubble.hasHover:hover, .bubble-selected {
    border: 2px solid #fd9843;
    /* border: 2px solid #6F38C5; */
    border-radius: 5px;
    color: #fd9843;
    /* color: #6F38C5; */
    margin-right: 10px;
    cursor: pointer;
}
.game-date-time { color: #9a9a9a; }
@media (min-width: 750px) {
    .bubble { font-size: 12px !important; }
    .game-date-time { font-size: 11px; }
}
@media (max-width: 749px) {
    .bubble { font-size: 11px !important; }
    .game-date-time { font-size: 10px; }
}

.filter-group > .row {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}
.filter-group > .row > .col-auto { display: inline-block; }
/* Hide scrollbar for Chrome, Safari and Opera */
.filter-group > .row::-webkit-scrollbar { display: none; }
/* Hide scrollbar for IE, Edge and Firefox */
.filter-group > .row {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}



/******************************************************************************************************************************************************************************************************************
* GENERAL PLAYER ROW
******************************************************************************************************************************************************************************************************************/
.card { cursor: pointer; }
.line-height { line-height: 20px; }
.game { min-width: 100px; }

.hit-group > .row {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}
.hit-group > .row > .col-auto { display: inline-block; }
/* Hide scrollbar for Chrome, Safari and Opera */
.hit-group > .row::-webkit-scrollbar { display: none; }
/* Hide scrollbar for IE, Edge and Firefox */
.hit-group > .row {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


/******************************************************************************************************************************************************************************************************************
* RESIZE TEXT INSIDE EACH PLAYER ROW
******************************************************************************************************************************************************************************************************************/
.player-name, .player-stat { font-family: sans-serif; }
@media (min-width: 750px) {
    .short { width: 100px !important; }
    .player-name { font-size: 16px !important; }
    .player-stat { font-size: 15px !important; }
    .player-over, .player-under { font-size: 15px !important; }
    .game-opp-odds-row { font-size: 12px; }
    .fs-7 { font-size: .9rem !important; }
    .hit-group { max-width: calc(100% - 215px) !important; }
    .perc {
        min-width: 100px;
        border-radius: 3px;
        font-weight: 600;
        font-size: 13px;
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }
}
@media (max-width: 749px) {
    .short { width: 3.3rem !important; }
    .player-name { font-size: 14px !important; }
    .player-stat { font-size: 13px !important; }
    /* .player-over, .player-under { font-size: 14px !important; } */
    .player-over, .player-under { font-size: 13px !important; }
    /* .time-span { display: none !important; } */
    .time-span { font-size: 10px !important; }
    .game-opp-odds-row { font-size: 11px !important; }
    .player-odds { font-size: 11px !important; }
    .hit-group { max-width: calc(100% - 120px) !important; }
    .perc {
        border-radius: 3px;
        font-weight: 600;
        font-size: 11px;
        padding: 2px 4px !important;
    }
    .over-o, .under-u { font-size: 13px !important; }
}



/***************************************************************************************************************************************************************
* LOADING SPINNER
***************************************************************************************************************************************************************/
.loader {
    z-index: 9999;
    margin: 5% auto;

    width: 120px;
    height: 120px;
    background-image: url('../../public/logo512.png');
    background-size: contain;
    background-repeat: no-repeat;

    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    /* visibility: hidden; */
    /* display: none; */
}
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}




/******************************************************************************************************************************************************************************************************************
* LEAGUE WINNER
******************************************************************************************************************************************************************************************************************/
.text-nba-winner {
    background: -webkit-linear-gradient(#8b2131, #fec524);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-nfl-winner {
    background: -webkit-linear-gradient(#e31837, #ffb612);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-steelers {
    background: -webkit-linear-gradient(#FFB612, #414546);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-nhl-winner {
    background: -webkit-linear-gradient(#B4975A, #333F42);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}