.section-selector { cursor: pointer; }
.section-selector:hover, .section-selected { background-color: #383f45; }
#current_password, #new_password, #new_password2 { height: 40px; }
#otherPage, #type, #page { height: 45px !important; }
#notes {
    min-height: 100px;
    height: 150px;
    resize: vertical;
}



@media (max-width: 545px) {
    .account-sidebar { width: 100%; margin-bottom: 10px; }
}